import * as React from "react";
import {
  Box,
  Text,
  Heading,
  Center,
  Button,
  Flex,
} from "@chakra-ui/react";
import Layout from "../components/layout";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Head from "../components/head";

const IndexPage = () => {
  const data = useStaticQuery(graphql`query HomeQuery {
  homeImg: file(
    sourceInstanceName: {eq: "images"}
    relativePath: {eq: "grids1.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(height: 400, layout: CONSTRAINED)
    }
  }
}
`);

  return (
    <Layout>
      <Head
        title="Create 15 - Home"
        description="Laser cutting and engraving in Johannesburg. Various materials like MDF, wood, bamboo and perspex."
      />
      <Center h="80px" color="black" pt={4}>
        <Heading as="h1">Laser cutting and engraving</Heading>
      </Center>
      <Center h="100px" color="black" maxW="6xl">
        <Heading fontSize="xl" as="h2">
          small scale laser cutting and engraving of wood, perspex and other
          materials
        </Heading>
      </Center>
      <Flex justifyContent="center">
        <Box display="block" minWidth="400px">
          <GatsbyImage
            image={data.homeImg.childImageSharp.gatsbyImageData}
            imgStyle={{ borderRadius: "10px" }}
            alt="Laser cut grids" />
        </Box>
      </Flex>
      <Box p={8} textAlign="center">
        <Text fontSize="xl">
          Have you got a custom design you want to engrave onto wood or another
          material?
        </Text>
        <Link to="/submit-design/">
          <Button m={4} colorScheme="brandOrange" bgColor="brandOrange.300">
            Submit a design
          </Button>
        </Link>

        {/* <Text fontSize="xl">
          Browse the products we have on sale. Many of the products can be
          customized with an engraving.
        </Text>
        <Link to="/products/">
          <Button m={4} colorScheme="brandOrange" bgColor="brandOrange.300">
            Products
          </Button>
        </Link> */}
      </Box>
    </Layout>
  );
};

export default IndexPage;
